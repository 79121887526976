<template>
  <v-container>
    <v-toolbar flat class="mt-10">
      <v-spacer />
      <v-btn color="primary" dark class="mb-10 mx-5" @click="onDownloadAll">
        {{ $t('attachments.download-all') }}
        <v-icon right dark> mdi-cloud-download </v-icon>
      </v-btn>
      <v-btn
        v-if="hasPermission('api.add_order') && !disableUpload"
        color="primary"
        dark
        class="mb-10 mx-5"
        @click="onUploadDialog"
      >
        {{ $t('attachments.upload') }}
        <v-icon right dark> mdi-cloud-upload </v-icon>
      </v-btn>
      <slot name="buttons"></slot>
    </v-toolbar>
    <template :attachments="{ deleted: deleted }"></template>
    <v-layout v-if="items" row wrap>
      <v-card
        v-for="at in items"
        :key="at.id"
        :color="color(at)"
        dark
        elevation="0"
        max-width="300"
        min-width="300"
        :min-height="disableDelete ? 70 : 120"
        class="ma-2 d-flex flex-column"
        @click="onSelected(at)"
      >
        <v-card-text class="font-weight-bold py-1">
          <v-icon large left> mdi-attachment </v-icon>
          {{ at.name }}
        </v-card-text>

        <v-spacer />

        <v-card-actions class="pt-0" align="end">
          <v-list-item class="grow">
            <v-row>
              <v-col cols="8" class="text-left">{{ toDate(at) }}</v-col>
              <v-col v-if="!disableDelete" class="text-right">
                <v-icon
                  v-if="hasPermission(permission)"
                  small
                  @click.stop="onDelete(at)"
                >
                  mdi-delete
                </v-icon>
              </v-col>
            </v-row>
          </v-list-item>
        </v-card-actions>
      </v-card>
    </v-layout>

    <v-row>
      <object
        v-if="previewUrl"
        :data="previewUrl"
        type="application/pdf"
        style="min-height: 100vh; width: 100%"
      >
        <p>
          Your web browser doesn't have a PDF plugin.
          <a :href="previewUrl">click here to download the PDF file.</a>
          <PDFViewer :source="previewUrl" style="height: 100vh; width: 100vw" />
        </p>
      </object>
    </v-row>

    <v-dialog v-model="uploadDialog">
      <upload
        :title="$t('attachments.upload')"
        :statuses="statuses"
        @close="onClose"
        @upload="onUpload"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import mixin from '@/mixin.js';
import PDFViewer from 'pdf-viewer-vue/dist/vue2-pdf-viewer';

export default {
  components: {
    upload: () => import('@/views/dashboard/components/files/upload.vue'),
    PDFViewer
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    previewUrl: {
      type: String,
      required: true
    },
    disableUpload: {
      type: Boolean
    },
    disableDelete: {
      type: Boolean
    },
    permission: {
      type: String,
      required: true
    },
    statuses: {
      type: Array,
      default: () => []
    }
  },
  data: () => {
    return {
      selected: null,
      page: 1,
      pageCount: 0,
      uploadDialog: false
    };
  },
  watch: {
    items: {
      handler() {
        console.log('attachments.items changed; selected:', this.selected);
        this.init();
      },
      deep: true
    }
  },
  async mounted() {
    console.log('attachments.mounted');
    await this.init();
  },
  async activated() {
    console.log('attachments.activated');
    await this.init();
  },
  methods: {
    async init() {},

    onUploadDialog() {
      this.uploadDialog = true;
    },

    async onUpload(files) {
      console.log('onUpload:', files);
      this.$emit('upload', files);
    },

    onDownloadAll() {
      // console.log('Download all');
      this.$emit('download-all');
    },

    async onClose() {
      this.uploadDialog = false;
      this.$emit('close');
    },

    async onSelected(attachment) {
      if (this.selected?.id === attachment.id) {
        attachment = '';
        this.selected = null;
      } else {
        this.selected = attachment;
      }

      console.log('onSelected:', attachment);
      this.$emit('select', attachment);
    },

    async onDelete(attachment) {
      console.log('onDelete:', attachment);
      const res = await this.$swal({
        html: this.$t('attachments.delete-confirmation'),
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      });

      if (res.isDismissed) {
        return;
      }

      this.$emit('delete', attachment);
    },

    // will be used by parent to confirm successful deletion
    async deleted(attachment) {
      console.log('deleted:', attachment);
      if (attachment.id === this.selected?.id) {
        this.selected = null;
        this.$emit('select', null);
      }
    },

    color(attachment) {
      if (attachment.id === this.selected?.id) {
        return 'accent';
      }
      return 'secondary';
    },

    error(msg) {
      this.$emit('error', msg);
    },

    toDate(at) {
      let d = at.created_at;
      if (mixin.methods.isString(d)) {
        d = mixin.methods.dateFromISO8601(d);
      }

      return d ? d.toLocaleString() : '';
    }
  }
};
</script>

<style>
.wrapper {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  -overflow-scrolling: touch;
}
</style>
